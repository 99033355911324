import { ISchemaResponse, ISchema, ISchemaResponseOld, SchemaFieldTypeEnum, SchemaDataTypeEnum, SchemaCustomKeys } from 'app/types/environments';
import { v4 as uuidv4 } from 'uuid';
import { ALIAS_DECIMAL_KEYS, ALIAS_RANGE_KEYS, STRING_KEYS } from '../border';

const getAliasKeyType = (key: string) => {
  if (STRING_KEYS.includes(key)) {
    return SchemaDataTypeEnum.string;
  }
  if (ALIAS_RANGE_KEYS.includes(key)) {
    return SchemaDataTypeEnum.integer;
  }
  if (ALIAS_DECIMAL_KEYS.includes(key)) {
    return SchemaDataTypeEnum.decimal;
  }
  return SchemaDataTypeEnum.string;
}

export const convertResponseAliasesForForm = (schema: ISchemaResponse): ISchema[] => {
  if (schema?.config_version === '2') {
    const entries = Object.entries((schema as unknown as ISchemaResponseOld).keyMapping || {});

    return entries?.map(([modelKey, modelBody]) => {
      const fields = Object.entries(modelBody.fields || {})?.map(([alias, key]) => ({ alias, key, dataType: getAliasKeyType(key), fieldType: SchemaFieldTypeEnum.indexed }));
      fields.unshift({ alias: SchemaCustomKeys.recordKey, key: SchemaCustomKeys.recordKey });
      return ({ key: modelKey, externalId: modelBody.external_id, fields: fields.sort((a, b) => a.key === SchemaCustomKeys.recordKey ? -1 : b.key === SchemaCustomKeys.recordKey ? 1 : 0), autoAssign: true })
    });
  }

  const entries = Object.entries(schema.keyMapping || {});

  return entries?.map(([modelKey, modelBody]) => {
    const fields = Object.entries(modelBody.fields || {})?.map(([alias, keyData]) => {
      if (keyData?.map_to === SchemaCustomKeys.recordKey) {
        return ({ alias, key: keyData?.map_to });
      }
      if (keyData?.field_type === SchemaFieldTypeEnum.encrypted) {
        return ({ alias, key: keyData?.map_to, dataType: keyData?.data_type, fieldType: keyData?.field_type, path: keyData?.path?.replace('$.', '') });
      }
      return ({ alias, key: keyData?.map_to, dataType: keyData?.data_type, fieldType: keyData?.field_type });
    }).sort((a, b) => a.key === SchemaCustomKeys.recordKey ? -1 : b.key === SchemaCustomKeys.recordKey ? 1 : 0);
    return ({ key: modelKey, externalId: modelBody.external_id, fields, autoAssign: true })
  })
}

export const convertResponseAliasesForBackend = (schema: ISchema[]): ISchemaResponse => {
  return ({
    keyMapping: schema?.reduce((acc, cur) => {
      const fields = cur?.fields?.reduce((acc, cur) => {
        if (cur?.key === SchemaCustomKeys.recordKey) {
          return ({ ...acc, [cur?.alias]: { map_to: cur.key } });
        }
        if (cur?.fieldType === SchemaFieldTypeEnum.encrypted) {
          return ({ ...acc, [cur?.alias]: { map_to: SchemaCustomKeys.body, data_type: cur?.dataType, field_type: cur?.fieldType, path: '$.' + cur?.path } });
        }
        return ({ ...acc, [cur?.alias]: { map_to: cur?.key, data_type: cur?.dataType, field_type: cur?.fieldType } });
      }, {})
      return ({ ...acc, [cur?.key]: ({ external_id: cur?.externalId || uuidv4(), fields }) })
    }, {})
  })
}

export const getAliasOrKey = (modelExternalId: string, schema: ISchema[], keys: string[]) => {
  if (modelExternalId) {
    const model = schema?.find(v => v.externalId === modelExternalId);
    if (model) {
      return keys?.map(v => {
        const alias = model.fields?.find(field => field.key === v);
        return alias?.alias || v;
      })
    }
  }

  return keys;
}
