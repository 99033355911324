import { DefaultTFuncReturn } from 'i18next';
import React from 'react';
import { Control, FieldError, Path, RegisterOptions } from 'react-hook-form';

export type FormDefaultValuesType = Record<string, string | number>;

export interface FormSelectOptionInterface<T = string> {
  label: T;
  value: T;
  group?: string;
  disabled?: boolean;
}

export type RuleValueType = string | number;
export type RuleType = Record<string, RuleValueType>;
export type RulesType = Omit<
  RegisterOptions<RuleType, Path<RuleType>>,
  'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
>;
export interface FormControlBaseProps<T> {
  name: Path<T>;
  label?: string | DefaultTFuncReturn;
  defaultValue?: T;
  control: Control<T>;
  helperText?: React.ReactNode | string;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  disabled?: boolean;
  error?: FieldError;
  type?: string;
  testId?: string;
}

export interface FormControllersMember<T> {
  name: keyof T;
  rules?: RulesType;
  disabled?: boolean;
  multi?: boolean;
}

export enum FormMode {
  create = 'create',
  edit = 'edit',
}
