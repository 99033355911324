import { EnvironmentResponseData, EnvironmentTypes, IEnvironmentCountry } from 'app/types/environments';
import { ServiceResponse } from 'app/types/services';

class Environment {
  static $TypeID: 'Environment';
  public id: string;
  public name: string;
  public type: EnvironmentTypes;
  public services: ServiceResponse[];
  public dtkEnabled: boolean;
  public removed: boolean;
  public removedAt: Date | null;
  public countries: IEnvironmentCountry[];
  public hideKeys?: boolean;

  constructor(data: unknown) {
    if (isEnvironmentResponse(data)) {
      this.id = data.id;
      this.name = data.name;
      this.type = data.type;
      this.services = data.services;
      this.dtkEnabled = !!data.dtk_enabled;
      this.removed = typeof data.removed_at === 'string';
      this.removedAt = data.removed_at ? new Date(data.removed_at) : null;
      this.countries = data.countries;
      this.hideKeys = data?.hide_keys;
    } else {
      throw new Error('Data is not Environment response');
    }
  }
}

const isEnvironmentResponse = (data: unknown): data is EnvironmentResponseData => {
  const d = data as EnvironmentResponseData;
  return (
    d?.id !== undefined &&
    d?.name !== undefined &&
    d?.type !== undefined &&
    d?.services !== undefined
  );
};

export default Environment;
