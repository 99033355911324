import Company from 'app/api/models/Company';
import PortalConfig from 'app/api/models/PortalConfig';
import { Roles } from 'app/types/auth';
import { CompanyInterfaceResponse } from 'app/types/company';
import { CurrentUserResponse } from 'app/types/user';
import { deletedOrgAccessList, generalAccessList, samlAccessList } from 'app/utils/auth/accessLists';
import { Route } from 'app/utils/constants';

export class CurrentUser {
  static $TypeID: 'CurrentUser';
  public id: string;
  public email: string;
  public defaultOrgId: string;
  public defaultOrgName = 'Unknown Company';
  public role: Roles = Roles.guest;
  public orgs: CompanyInterfaceResponse[];
  public totpEnabled: boolean;
  public defaultOrg: Company;

  constructor(data: unknown) {
    if (isCurrentUserResponse(data)) {
      this.id = data.id;
      this.email = data.email;
      this.defaultOrgId = data.company_id;
      this.orgs = data.companies;
      this.totpEnabled = data.totp_enabled;

      const defaultCompany = data?.companies?.filter(v => v.id === data.company_id)?.[0];
      if (defaultCompany) {
        this.role = defaultCompany?.role;
        this.defaultOrgName = defaultCompany.name;
        this.defaultOrg = new Company(defaultCompany);
      }
    } else {
      throw new Error('Data is not CurrentUserResponse');
    }
  }

  public isOwnerRole = (): boolean => this.role === Roles.owner;
  public isAdministratorRole = (): boolean => this.role === Roles.administrator;
  public iAuditorRole = (): boolean => this.role === Roles.auditor;
  public isManagerRole = (): boolean => this.role === Roles.manager;
  public isMemberRole = (): boolean => this.role === Roles.member;
  public isAccessGranted = (route: string | Route): boolean => {
    if (route instanceof Route) route = route.pathname;
    const acl = this.defaultOrg?.removedAt ? deletedOrgAccessList : this.isSamlUser() ? samlAccessList : generalAccessList;
    return acl[route]?.includes(this.role) && PortalConfig.acl[route] !== false;
  };
  public isAccessDenied = (route: string | Route): boolean => !this.isAccessGranted(route);
  public isSamlUser = (): boolean => !!this.defaultOrg?.samlId && this.defaultOrg.samlId.length > 0;
}

const isCurrentUserResponse = (data: unknown): data is CurrentUserResponse => {
  const d = data as CurrentUserResponse;
  return d?.id !== undefined && d?.email !== undefined;
};
