import getConfig from 'next/config';

import PortalConfig from 'app/api/models/PortalConfig';
import { NextGetConfig } from 'app/types/nextjs';
import { isProduction } from 'app/utils/environmentVariables';

const {
  publicRuntimeConfig: { IC_RELEASE_TAG, IC_DEPLOY_ENVIRONMENT, IC_TRAVIS_BUILD_NUMBER },
} = getConfig() as NextGetConfig;

const dsn =
  IC_DEPLOY_ENVIRONMENT?.toLowerCase() === 'production'
    ? 'https://172db585af364433a43c163d8366a47e@o418108.ingest.sentry.io/5515745'
    : 'https://9d695171cf5248eb85afdc68f18a24e8@o418108.ingest.sentry.io/5515725';

const configureSentry = (): void => {
  if (isProduction() && PortalConfig.isDefault()) {
    import('@sentry/react').then(sentry =>
      sentry.init({
        dsn,
        release: `portal-frontend@${IC_RELEASE_TAG}@${IC_TRAVIS_BUILD_NUMBER}`,
        environment: IC_DEPLOY_ENVIRONMENT,
      }),
    );
  }
};

export default configureSentry;
