import { ServiceResponse, ServiceTypesEnum } from 'app/types/services';
import { CountryTenant } from './infraJson';
import { CountryCode } from './country';
import { PoliciesFieldProtectionMode, PoliciesMaskingParentStrategyType, PoliciesMaskingType, PolicyScopeComparisonFilterTypes, PolicyScopeFilterTypes } from './policies';
import { FormSelectOptionInterface } from './forms';


export interface ISdk {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  autogenerated?: boolean;
  secret?: string;
  migration_client?: boolean;
  auth_endpoint?: string;
  portal_address?: string;
  policy_external_id?: string;
  auth_url?: string;
  entry_url?: string;
}

export interface SdkResponse {
  data: ISdk[];
  migration_client: ISdk;
}

export interface IEnvironmentCountry {
  country_code: CountryCode;
  increment: string;
  customer_code: CountryTenant;
  services: {
    id: string;
    name: string;
    code: ServiceTypesEnum;
  }[];
  activated: boolean;
}

export interface EnvironmentResponseData {
  $TypeID: string;
  id: string;
  type: EnvironmentTypes;
  name: string;
  dtk_enabled: boolean;
  services: ServiceResponse[];
  removed_at: string | null;
  countries: IEnvironmentCountry[];
  hide_keys?: boolean;
}

export interface CreateEnvironmentResponse {
  id: string;
  name: string;
  type: string;
}

export interface CreateEnvironmentRequest {
  type: string;
  name: string;
  dtk?: string;
}

export enum EnvironmentTypes {
  dev = 'Dev',
  test = 'Test',
  staging = 'Staging',
  integration = 'Integration',
  uat = 'U.A.T.',
  production = 'Production',
}

export enum SchemaDataTypeEnum {
  string = 'string',
  integer = 'integer',
  decimal = 'decimal',
  date = 'date',
  time = 'time',
  datetime = 'datetime',
  boolean = 'boolean',
  json = 'json'
}

export enum SchemaFieldTypeEnum {
  indexed = 'indexed',
  indexedHashed = 'indexed_hashed',
  encrypted = 'encrypted',
}

export interface ISchemaFieldResponse {
  map_to: string;
  data_type: SchemaDataTypeEnum;
  field_type: SchemaFieldTypeEnum;
}

export interface ISchemaResponseOld {
  keyMapping: {
    [key: string]: {
      external_id: string;
      fields: {
        [alias: string]: string;
      }
    }
  }
  config_version: string;
}

export interface ISchemaResponse {
  keyMapping: {
    [key: string]: {
      external_id: string;
      fields: {
        [alias: string]: ISchemaFieldResponse;
      }
    }
  }
  config_version?: string;
}

export interface IFieldsRoot {
  keyMapping: ISchema[];
}

export interface ISchema {
  key: string;
  externalId: string;
  fields: IField[];
  autoAssign?: boolean;
}

export interface IField {
  key: string;
  fieldType?: SchemaFieldTypeEnum;
  dataType?: SchemaDataTypeEnum;
  alias: string;
  path?: string;
}

export type IPolicyScopeFilterComparison = { type?: PolicyScopeComparisonFilterTypes; value: string };

export type IPolicyScopeFilter = {
  key?: string;
  type?: PolicyScopeFilterTypes;
  value?: string | string[] | object;
  subValues?: IPolicyScopeFilterComparison[]
}

interface IPolicyScope {
  filter: IPolicyScopeFilter[];
}

export interface IPolicyMaskingStrategyOptions {
  maskingLength: number;
  maskingChar: string;
  initialsLength: number;
  delimiter?: string;
  type?: PoliciesMaskingType.alphanumeric | PoliciesMaskingType.email;
}

export interface IPolicyRedactedStrategyOptions {
  formula: {
    transforms: FormSelectOptionInterface[];
    format: string;
  }
}

export interface IPolicyMaskingField {
  name: string;
  strategy?: PoliciesMaskingType | PoliciesMaskingParentStrategyType;
  strategyOptions?: IPolicyMaskingStrategyOptions | IPolicyRedactedStrategyOptions;
  valueType?: PolicyFieldValueType;
}

interface IPolicyMasking {
  mode: PoliciesFieldProtectionMode;
  fields: IPolicyMaskingField[];
}

export interface IPolicy {
  external_id: string;
  name: string;
  scope?: IPolicyScope;
  fields: string[];
  actions: boolean[];
  model: string;
  masking?: IPolicyMasking;
  external_validation_url: string;
  cors?: {
    allowed_origin?: string | { value: string }[];
  },
  enableAllAllowOrigin?: boolean;
}

export interface IPolicyResponse {
  external_id: string;
  name: string;
  scope?: {
    filter: {
      [key: string]: string | string[] | object
    }
  };
  fields: string[];
  actions: string[];
  external_validation_url: string;
  cors?: {
    allowed_origin: string | string[];
  },
  model: string;
  masking?: IPolicyMasking;
}

export enum PolicyActions {
  read = 'Read',
  write = 'Write',
  delete = 'Delete',
}

export enum PolicyFieldValueType {
  original = 'original',
  masked = 'masked',
  redacted = 'redacted',
}

export interface IEnvironmentFirewallResponse {
  enabled: boolean;
  countries: { [key: string]: IEnvironmentFirewallCountryResponse };
}

export interface IEnvironmentFirewallCountryResponse {
  enabled: boolean;
  whitelist: {
    ip: string[];
  }
}

export interface IEnvironmentFirewall {
  enabled: boolean;
  countries: IEnvironmentFirewallCountry[];
}

export interface IEnvironmentFirewallCountry {
  countryCode: string;
  enabled: boolean;
  whitelistIp: { value: string }[];
}

export enum EnvironmentConfigurationManageType {
  import = 'import',
  export = 'export'
}

export enum SchemaCustomKeys {
  recordKey = 'record_key',
  isEncrypted = 'is_encrypted',
  version = 'version',
  attachments = 'attachments',
  body = 'body',
  json = 'json_body',
  primaryKey = 'primary_key',
}

export enum PolicyRedactedTransforms {
  trim = 'trim',
  toLowerCase = 'toLowerCase',
  toUpperCase = 'toUpperCase',
}
