import api from 'app/api/client';
import { CurrentUser } from 'app/api/models/CurrentUser';
import { ICountryDataV2 } from 'app/types/dashboard';
import { useRouter } from 'next/router';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { DATE_FROM_START, getTodayDateIsoStr, Routes } from '../constants';
import { errorHandler } from '../errors';
import { useSnackbarContext } from './SnackbarProvider';
import { EnvironmentResponseData } from 'app/types/environments';

interface IProps {
  children: JSX.Element | JSX.Element[];
  user?: CurrentUser;
}

interface UserContextValue extends Partial<CurrentUser> {
  fetching: boolean;
  usage: ICountryDataV2[];
  envWithDataId?: string;
  environments: EnvironmentResponseData[];
}

export const DEFAULT_VALUE: UserContextValue = { environments: [], usage: [], fetching: true };

export const UsageContext = createContext<UserContextValue>(DEFAULT_VALUE);

export const UsageProvider: FC<IProps> = ({ user, children }) => {

  const [usage, setUsage] = useState<ICountryDataV2[]>([]);
  const [environments, setEnvironments] = useState<EnvironmentResponseData[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [envWithDataId, setEnvWithDataId] = useState<string>();
  const { openSnackbar } = useSnackbarContext();
  const router = useRouter();

  useEffect(() => {
    if (!user) return;
    const getUsage = async () => {
      try {
        const environments = await api?.fetchEnvironments();

        const activatedEnvs = environments?.filter(v => v?.countries?.filter(cnt => cnt?.activated)?.length > 0);

        const usageResPromises = activatedEnvs?.map(async env => {
          return await api.fetchUsageByEnv(env?.id, DATE_FROM_START, getTodayDateIsoStr());
        });

        const usageResResolved = await Promise.all(usageResPromises);
        usageResResolved.forEach((v, idx) => {
          if (v?.length > 0 && envWithDataId === undefined) {
            setEnvWithDataId(activatedEnvs?.[idx]?.id);
          }
        });
        const usageRes = usageResResolved?.flatMap(v => v);

        setUsage(usageRes);
        setEnvironments(environments);
      } catch (err) {
        errorHandler(err as Error, openSnackbar);
      } finally {
        setFetching(false);
      }
    };
    if (router.pathname !== Routes.company.deleted().toString()) {
      getUsage();
    }
  }, [api, openSnackbar, router?.pathname]);

  return <UsageContext.Provider value={{ envWithDataId, environments, usage, fetching }}>{children}</UsageContext.Provider>;
};

export const useUsageContext = () => useContext(UsageContext);
